import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import NewsDetails from './news-components/news-details';
import Footer from './global-components/footer';

const NewsDetailsPage = () => {
    const { newsId } = useParams(); // Get news ID from URL params
    const location = useLocation(); // Access the passed state
    const [newsData, setnewsData] = useState(location.state ? location.state.news : null);
    const [error, setError] = useState(null);

    


    
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        
        function setData(data = null) {
            if (location.state) {
                if (location.state.news) {
                    setnewsData(location.state.news);
                } else if (location.state.recentPosts) {
                    setnewsData(location.state.recentPosts);
                }else if (location.state.popularPosts) {
                    setnewsData(location.state.popularPosts);
                }else if (location.state.trendingPosts) {
                    setnewsData(location.state.trendingPosts);
                }else if (location.state.latestNews) {
                    setnewsData(location.state.latestNews);
                }else if (location.state.TrendingNews) {
                    setnewsData(location.state.TrendingNews);
                }else if (location.state.WhatsNews) {
                    setnewsData(location.state.WhatsNews);
                }
                else {
                    setnewsData(data);
                }
            }      
        }
        setData(null);

        // Fetch the news data only if it was not passed via state
        if (!newsData && newsId) {
            fetch(`https://sportysphere.com/api/blog.php?news=${newsId}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Error fetching news data');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Fetched data:', data); // Log the response data
                    setnewsData(data);
                    setData(data)

                })
                .catch((error) => setError(error.message));
        }
    }, [location.state, newsId, newsData]);


    // Ensure newsData is available
    if (error) {
        return <p>{error}</p>;
    }


    // Handle cases where tags, relatedPosts, or comments are missing or undefined
    const publicUrl = process.env.PUBLIC_URL + '/';

    return <div>
        <Navbar />
        <PageHeader headertitle="News Details"  />
        <NewsDetails
        newsData={newsData}
        publicUrl={publicUrl}
        />
        <Footer />
    </div>
}

export default NewsDetailsPage

