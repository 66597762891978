import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import Footer from './global-components/footer';

const BlogDetailsPage = () => {
    const { blogId } = useParams(); // Get blog ID from URL params
    const location = useLocation(); // Access the passed state
    const [blogData, setBlogData] = useState(location.state ? location.state.blog : null);
    const [error, setError] = useState(null);

    


    
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        
        function setData(data = null) {
            if (location.state) {
                if (location.state.blog) {
                    setBlogData(location.state.blog);
                }else if (location.state.blogs) {
                    setBlogData(location.state.blogs);
                }else if (location.state.recentPosts) {
                    setBlogData(location.state.recentPosts);
                }else if (location.state.popularPosts) {
                    setBlogData(location.state.popularPosts);
                }else if (location.state.trendingPosts) {
                    setBlogData(location.state.trendingPosts);
                }else {
                    setBlogData(data);
                }
            }      
        }
        setData(null);

        // Fetch the blog data only if it was not passed via state
        if (!blogData && blogId) {
            fetch(`https://sportysphere.com/api/blog.php?blog=${blogId}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Error fetching blog data');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Fetched data:', data); // Log the response data
                    setBlogData(data);
                    setData(data)

                })
                .catch((error) => setError(error.message));
        }
    }, [location.state, blogId, blogData]);


    // Ensure blogData is available
    if (error) {
        return <p>{error}</p>;
    }


    // Handle cases where tags, relatedPosts, or comments are missing or undefined
    const publicUrl = process.env.PUBLIC_URL + '/';

    return <div>
        <Navbar />
        <PageHeader headertitle="Blog Details"  />
        <BlogDetails
        blogData={blogData}
        publicUrl={publicUrl}
        />
        <Footer />
    </div>
}

export default BlogDetailsPage

