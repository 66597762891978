import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class MiniSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendingPosts: [],
      recentPosts: [],
      popularPosts: [],
    };
  }

  componentDidMount() {
    // Fetching trending posts
    fetch('https://sportysphere.com/api/blog.php?blog=trending_posts&type=blog')
      .then(response => response.json())
      .then(data => this.setState({ trendingPosts: data }))
      .catch(error => console.error('Error fetching trending posts:', error));

    // Fetching recent posts
    fetch('https://sportysphere.com/api/blog.php?blog=recent_posts&type=blog')
      .then(response => response.json())
      .then(data => this.setState({ recentPosts: data }))
      .catch(error => console.error('Error fetching recent posts:', error));

    // Fetching popular posts
    fetch('https://sportysphere.com/api/blog.php?blog=popular_posts&type=blog')
      .then(response => response.json())
      .then(data => this.setState({ popularPosts: data }))
      .catch(error => console.error('Error fetching popular posts:', error));
  }

  renderPostList(posts, imageFetchPath, formatDate, publicUrl) {
    return posts.map(post => (
      <div className="single-post-list-wrap" key={post.id}>
        <div className="media">
          <div className="media-left">
            <img src={post.post_image ? `${imageFetchPath}blog/${post.post_image}` : `${publicUrl}assets/img/post/list/1.png`} alt={post.title || 'image'} width={100}/>
          </div>
          <div className="media-body">
            <div className="details">
              <div className="post-meta-single">
                <ul>
                  <li><i className="fa fa-clock-o" />{formatDate(post.updated_at || post.created_at)}</li>
                </ul>
              </div>
              <h6 className="title">
                <Link to={{ pathname: `/blog-details/${post.id}`, state: { posts: post } }}>{post.title}</Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  render() {
    const { trendingPosts, recentPosts, popularPosts } = this.state;
    let imageFetchPath = 'https://sportysphere.com/public/img/';
    let publicUrl = process.env.PUBLIC_URL + '/';
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
      };
    return (
      <div className="col-xl-3 col-md-6">
        <div className="nxp-tab-inner nxp-tab-post-two mb-4">
          <ul className="nav nav-tabs" id="nx1" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="nx1-tab-1" data-toggle="pill" href="#nx1-tabs-1" role="tab" aria-selected="true">
                Recent
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="nx1-tab-2" data-toggle="pill" href="#nx1-tabs-2" role="tab" aria-selected="false">
                Popular
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="nx1-tab-3" data-toggle="pill" href="#nx1-tabs-3" role="tab" aria-selected="false">
                Trending
              </a>
            </li>
          </ul>
        </div>

        <div className="tab-content" id="nx1-content">
          <div className="tab-pane fade show active" id="nx1-tabs-1" role="tabpanel">
            {this.renderPostList(recentPosts, imageFetchPath, formatDate, publicUrl)}
          </div>
          <div className="tab-pane fade" id="nx1-tabs-2" role="tabpanel">
            {this.renderPostList(popularPosts, imageFetchPath, formatDate, publicUrl)}
          </div>
          <div className="tab-pane fade" id="nx1-tabs-3" role="tabpanel">
            {this.renderPostList(trendingPosts, imageFetchPath, formatDate, publicUrl)}
          </div>
        </div>
      </div>
    );
  }
}
