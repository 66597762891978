import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BannerV2 = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [loading, setLoading] = useState({
    blogs: true,
    categories: true,
    latestNews: true,
  });
  const [newsMessage, setNewsMessage] = useState('Loading News...');

  const publicUrl = process.env.PUBLIC_URL + '/';
  const imageFetchPath = 'https://sportysphere.com/public/img/';
  const imageAlt = 'image';

  // Helper function to format the date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});
  };

  // Fetch blogs data
  const fetchBlogs = async () => {
    try {
      const response = await fetch('https://sportysphere.com/api/blog.php?blog=home_posts&section=home_banner&limit=3');
      const data = await response.json();
      setBlogs(data);
      setLoading((prev) => ({ ...prev, blogs: false }));
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setLoading((prev) => ({ ...prev, blogs: false }));
    }
  };

  // Fetch categories data
  const fetchCategories = async () => {
    try {
      const response = await fetch('https://sportysphere.com/api/blog.php?category=blog_categories');
      const data = await response.json();
      setCategories(data);
      setLoading((prev) => ({ ...prev, categories: false }));
    } catch (error) {
      console.error('Error fetching categories:', error);
      setLoading((prev) => ({ ...prev, categories: false }));
    }
  };

  // Fetch latest news data
  const fetchLatestNews = async () => {
    try {
      const response = await fetch('https://sportysphere.com/api/blog.php?news=home_posts&section=latest_news&limit=6');
      const data = await response.json();
      setLatestNews(data);
      setLoading((prev) => ({ ...prev, latestNews: false }));
    } catch (error) {
      console.error('Error fetching latest news:', error);
      setLoading((prev) => ({ ...prev, latestNews: false }));
    }
  };

  // Manage the news message change after 20 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (latestNews.length === 0) {
        setNewsMessage('No News Found');
      }
    }, 20000);

    return () => clearTimeout(timer);
  }, [latestNews]);

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
    fetchLatestNews();
  }, []);

  return (
    <div className="post-area banner-post-area pt-4 pd-bottom-70">
      <div className="container">
        <div className="row">
          {/* Blogs Section */}
          <Blogs blogs={blogs} loading={loading.blogs} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} />

          {/* Latest News Section */}
          <LatestNewsSection latestNews={latestNews} loading={loading.latestNews} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} newsMessage={newsMessage} />

          {/* Categories Section */}
          <Categories categories={categories} loading={loading.categories} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} />
        </div>
      </div>
    </div>
  );
};

// Blogs Component
const Blogs = ({ blogs, loading, imageFetchPath, publicUrl, imageAlt, formatDate }) => (
  <div className="col-lg-6">
    {loading ? (
      <p>Loading Blogs...</p>
    ) : blogs.length > 0 ? (
      <>
        {/* First Blog Post */}
        <div className="single-post-wrap style-overlay">
          <div className="thumb">
            <img src={blogs[0].post_image ? imageFetchPath + 'blog/' + blogs[0].post_image : `${publicUrl}assets/img/blog/default.png`} alt={blogs[0].title || imageAlt} />
            <Link className="tag-base tag-blue" to={`/cat-blog/${blogs[0].category}`}>
              {blogs[0].category}
            </Link>
          </div>
          <div className="details">
            <div className="post-meta-single">
              <p>
                <i className="fa fa-clock-o" />
                {formatDate(blogs[0].updated_at || blogs[0].created_at)}
              </p>
            </div>
            <h5 className="title">
              <Link to={{ pathname: `/blog-details/${blogs[0].id}`, state: { blogs } }}>{blogs[0].title}</Link>
            </h5>
          </div>
        </div>

        {/* Second and Third Blog Posts */}
        <div className="row">
          {blogs.slice(1, 3).map((blog, index) => (
            <div className="col-lg-6 col-sm-6" key={index}>
              <div className="single-post-wrap style-overlay">
                <div className="thumb">
                  <img src={blog.post_image ? imageFetchPath + 'blog/' + blog.post_image : `${publicUrl}assets/img/blog/default.png`} alt={blog.title || imageAlt} />
                </div>
                <div className="details">
                  <div className="post-meta-single">
                    <p>
                      <i className="fa fa-clock-o" />
                      {formatDate(blog.updated_at || blog.created_at)}
                    </p>
                  </div>
                  <h6 className="title">
                    <Link to={{ pathname: `/blog-details/${blog.id}`, state: { blogs } }}>{blog.title}</Link>
                  </h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    ) : (
      <p>No Blogs Found</p>
    )}
  </div>
);

// Latest News Component
const LatestNewsSection = ({ latestNews, loading, imageFetchPath, publicUrl, imageAlt, formatDate, newsMessage }) => (
  <div className="col-lg-3 col-sm-6">
    <div className="section-title style-two mb-4">
      <h6 className="title">Latest News</h6>
    </div>
    {loading ? (
      <p>{newsMessage}</p>
    ) : latestNews.length > 0 ? (
      latestNews.map((post, index) => (
        <div key={index} className="single-post-list-wrap">
          <div className="media">
            <div className="media-left">
              <img src={post.post_image ? `${imageFetchPath}news/${post.post_image}` : `${publicUrl}assets/img/post/list/1.png`} alt={post.title || imageAlt} width={100} />
            </div>
            <div className="media-body">
              <div className="details">
                <div className="post-meta-single">
                  <ul>
                    <li>
                      <i className="fa fa-clock-o" />
                      {formatDate(post.updated_at || post.created_at)}
                    </li>
                  </ul>
                </div>
                <h6 className="title">
                  <Link to={{ pathname: `/news-details/${post.id}`, state: { latestNews: post } }}>{post.title}</Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p>{newsMessage}</p>
    )}
  </div>
);

// Categories Component
const Categories = ({ categories, loading, imageFetchPath, publicUrl, imageAlt }) => (
  <div className="col-lg-3 col-sm-6 widget-category">
    <div className="section-title style-two mb-4">
      <h6 className="title">Category</h6>
    </div>
    <div className="row custom-gutters-14">
      {loading ? (
        <p>Loading Categories...</p>
      ) : categories.length > 0 ? (
        categories.map((category, index) => (
          <div key={index} className="col-sm-6">
            <div className="single-category-inner">
              <img src={category.category_image ? `${imageFetchPath}blog_category/${category.category_image}` : `${publicUrl}assets/img/blog/4.png`} alt={category.category || imageAlt} />
              <Link className="tag-base tag-blue" to={`/cat-blog/${category.id}`}>
                {category.slog}
              </Link>
            </div>
          </div>
        ))
      ) : (
        <p>No Categories Found</p>
      )}
    </div>
  </div>
);

export default BannerV2;
